import React, { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { conf, t } from '../../../../domain/services/configService';
import { cookieService } from '../../../../domain/services/cookieService';
import { theme } from '../../../../domain/theme';
import { IconClose } from '../../../Icon/IconClose';

const styles = {
  cookieFooter: css`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #000;
    z-index: 299;
    padding: 20px;

    & .icon-close {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 1.25rem;
      text-decoration: none;
      cursor: pointer;
    }

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
      ${theme.font.family('regularWeb')};
      font-weight: 400;
    }

    & > div > div:only-of-type {
      margin-top: 40px;
      margin-bottom: 60px;
    }

    & a {
      text-decoration: underline;
      color: #fff;
    }

    ${theme.mq.tablet} {
      & {
        height: 120px;
        padding: 30px 0;
      }

      & .icon-close {
        top: 10px;
        right: 10px;
      }

      & > div {
        margin: 0 55px;
        flex-direction: row;
      }

      & > div > div:only-of-type {
        margin: 0;
      }
    }
  `,
  cookieFooterActions: css`
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    flex-shrink: 0;
    text-align: center;
    width: 100%;

    ${theme.mq.tablet} {
      & {
        display: block;
        margin-top: 0;
        margin-left: auto;
        width: auto;
      }
    }
  `,
  cookieFooterCta: css`
    text-align: center;
    background: rgba(0, 0, 0, 0);
    color: #fc0;
    padding: 7px 0;
    text-transform: uppercase;
    border-radius: 0;
    ${theme.font.family('regularWeb')};
    font-weight: 400;
    font-size: 0.9375rem;
    letter-spacing: 2.5px;
    line-height: 1.5;
    border: 2px solid #fc0;
    cursor: pointer;
    line-height: 2.625rem;
    padding: 0;

    &:hover {
      text-align: center;
      background: rgba(0, 0, 0, 0);
      color: #fff;
      padding: 7px 0;
      text-transform: uppercase;
      border-radius: 0;
      padding: 0;
      border: 2px solid #fff;
    }

    ${theme.mq.tablet} {
      & {
        display: inline-block;
        width: 195px;
        margin-left: 60px;
        max-width: 100%;
      }
    }
  `,
  cookieFooterDesc: css`
    letter-spacing: 0.04em;
    color: #fff;
    font-size: 0.875rem;
    line-height: 1.25rem;

    ${theme.mq.tablet} {
      & {
        display: inline-block;
      }
    }
  `
};

export const CookieFooter = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOptOut, setIsOptOut] = useState(false);

  const optOut = () => {
    setIsOpen(true);
    setIsOptOut(true);
  };

  const cookieOptOut = () => {
    cookieService.remove('natgeo-cookie');
    optOut();
  };

  const placeholderEl = useRef();
  const fixedEl = useRef();

  const resolvePlaceholder = () => {
    if (fixedEl.current && placeholderEl.current) {
      placeholderEl.current.style.height = `${fixedEl.current.clientHeight}px`;
    }
  };

  useEffect(() => {
    if (!conf.activate_cmp) {
      const ngCookie = cookieService.get('natgeo-cookie');
      if (!ngCookie) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }
    window.addEventListener('cookie-opt-out', cookieOptOut);
    resolvePlaceholder();
    window.addEventListener('resize', resolvePlaceholder);
    return () => {
      document.removeEventListener('resize', resolvePlaceholder);
      document.removeEventListener('cookie-opt-out', cookieOptOut);
    };
  }, []);

  useEffect(() => {
    resolvePlaceholder();
  }, [isOpen]);

  const close = () => {
    setIsOpen(false);
  };

  const acceptTerms = () => {
    cookieService.set('natgeo-cookie', 'accepted', 365);
    close();
  };

  let msgStart = conf.cookiePolicy.cookieDescription;
  let msgLinkText = conf.cookiePolicy.informationDescription;
  let msgEnd = null;

  if (isOptOut) {
    const [optOutMsgStart, optOutLinkText, ...optOutMsgEndArr] =
      conf.cookiePolicy.optOutMessage.split('_');
    const optOutMsgEnd = optOutMsgEndArr.join('_');
    msgStart = optOutMsgStart.trim();
    msgLinkText = optOutLinkText;
    msgEnd = optOutMsgEnd;
  }

  return isOpen ? (
    <span>
      <div
        // eslint-disable-next-line no-return-assign
        ref={placeholderEl}
      />

      <div // eslint-disable-next-line no-return-assign
        ref={fixedEl}
        css={styles.cookieFooter}
      >
        <div>
          <div css={styles.cookieFooterDesc}>
            {`${msgStart} `}
            <a
              href={conf.cookiePolicy.informationLink.uri}
              rel="noopener noreferrer"
              target="_blank"
            >
              {msgLinkText}
            </a>
            {msgEnd}
          </div>
          {isOptOut ? (
            <span
              className="icon-close"
              role="button"
              tabIndex="0"
              onClick={close}
              label={t('close')}
              onKeyPress={close}
            >
              <IconClose color="white" />
            </span>
          ) : (
            <div css={styles.cookieFooterActions}>
              <div
                css={styles.cookieFooterCta}
                role="button"
                tabIndex="0"
                onClick={acceptTerms}
                onKeyPress={acceptTerms}
              >
                {conf.cookiePolicy.acceptButton}
              </div>
            </div>
          )}
        </div>
      </div>
    </span>
  ) : null;
};
